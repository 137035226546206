import React from 'react';
import {Box, Chip, Grid} from '@mui/material';
import shadow from '../assets/shadow.png';
import Typography from '@mui/material/Typography';
import { HashLink } from 'react-router-hash-link';

export const PlatformDrivers = ({Logo, text , platform}) => {

	return (
		<Box border={3} borderRadius={2} borderColor={'#e0dcdc'} width={'200px'} height={'220px'}
		     style={{background: '#ffffff'}}>
			<Grid container>
				<Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}} paddingTop={'30px'}>
					<Logo width={'120px'}/>
				</Grid>
				<Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}} paddingTop={'10px'}>
					<img
						src={shadow}
						alt="Shadow"
						style={{height: "10%"}}
					/>
					{/*<Box style={{ height: 20, width: 300, background: 'radial-gradient(#a69e9e, white 40%)'}}/>*/}
				</Grid>
				<Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
					<Typography variant={'subtitle2'} sx={{display: 'flex', justifyContent: 'center'}}>
						{text}
					</Typography>
				</Grid>
				<Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}} paddingTop={'20px'}>
					<Chip color="primary" label={'CHECK DRIVERS'} component={HashLink} smooth to={`/portfolio/${platform}#portfolioBrowser`} onClick={()=>{}}/>
				</Grid>
			</Grid>
		</Box>
	);
};

// const color = '#a69e9e';
