import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import SvgLogo from './svg/SvgLogo';
import {Grid, Tab, Tabs} from '@mui/material';
import {connect} from 'react-redux';
import {selectTab} from '../store/actions/navigation';
import {useLocation} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import {useEffect} from 'react';

function LinkTab(props) {
	return (
		<Tab component={HashLink} {...props} />
	);
}
const HsAppBar = ({pages}) => {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const location = useLocation();
	const [valueTab, setValueTab] = React.useState('/');
	useEffect(() => {
		for (let page of pages) {
			if (location.pathname.search(page.path) !== -1) {
				setValueTab(page.path);
			}
		}
	},[location.pathname, pages]);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar position="static" color="secondary">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Grid container sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}>
						<Grid item md={4} style={{display: 'flex', alignContent: "center", alignItems: "center"}}>
							<SvgLogo width={'200px'}/>
						</Grid>
						<Grid item md={8}>
							<Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
								<Tabs value={valueTab}>
									{pages.map((page, index) => (
										<LinkTab label={page.name} value={page.path} key={index} smooth to={`${page.path}#mainPanel`}/>
									))}
								</Tabs>
							</Box>
						</Grid>
					</Grid>
					<Grid container sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
						<Grid item xs={3}>
							<Box>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleOpenNavMenu}
									color="inherit"
								>
									<MenuIcon/>
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: {xs: 'block', md: 'none'},
									}}
								>
									{pages.map((page) => (
										<MenuItem key={page.name} onClick={() => handleCloseNavMenu(page)} component={HashLink} smooth to={`${page.path}#mainPanel`}>
											<Typography textAlign="center" color="textPrimary">{page.name}</Typography>
										</MenuItem>
									))}
								</Menu>
							</Box>
						</Grid>
						<Grid item xs={6} style={{display: 'flex', alignContent: "center", alignItems: "center"}}>
							<Box sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}>
								<SvgLogo width={'150px'}/>
							</Box>
						</Grid>
					</Grid>
				</Toolbar>
			</Container>
		</AppBar>
	)
		;
};

const mapStateToProps = (state) => {
	return {
		tabSelected: state.navigation.tabSelected,
		pages: state.pages
	};
};

export default connect(mapStateToProps, {selectTab})(HsAppBar);
