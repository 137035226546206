import {AxiosService} from '../../services/Axios.Service';
export const GET_DRIVERS = 'GET_DRIVERS';

export const getDrivers = (uri, params) => {
	return async dispatch => {
		try {
			const response = await AxiosService.post(uri, params);
			dispatch({
				type: GET_DRIVERS,
				payload: response.data
			});

		} catch (e) {
			dispatch({
				type: GET_DRIVERS,
				payload: []
			});
		}
	}
}
