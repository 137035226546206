import React from 'react';
import {Box, Button, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import {baseUrl} from '../services/Axios.Service';

export const DriverCard = ({driver}) => {
	const {title, img, text, marketplace_link} = driver;

	return (
		<Box sx={{maxWidth: '750px' , overflow:'clip', paddingLeft: 2, paddingRight:2}}>
			<Grid container>
				<Grid item xs={12} md={12} lg={12}>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant={'h4'}>{title}</Typography>
						</Grid>
						<Grid item xs={12} md={8}>
							<Grid container>
								<Grid item xs={12} sx={{display:{xs:'flex', md:'none'}}}>
									<div style={{width:"100%"}}>
										<div style={{float:"right", width:"150px"}}><img src={`${baseUrl}${img}`}
										                                              alt={title}
										                                              style={{width: '150px'}}/>
										</div>
										<div style={{float: "none"}}>
											<Typography variant={'body2'}>
												{text}
											</Typography>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sx={{display:{xs:'none', md:'flex'}}}>
									<Typography variant={'body2'}>
										{text}
									</Typography>
								</Grid>
								<Grid item xs={12}
								      sx={{
									      paddingTop: 3,
									      display: 'flex',
									      justifyContent: 'center',
									      alignItems: 'center'
								      }}>
									<Button size="large" variant="contained" sx={{borderRadius: 5}} href={marketplace_link} target="_blank">
										Download
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4} sx={{display: {xs: 'none', md: 'flex'}}}>
							<img src={`${baseUrl}${img}`} alt={title} style={{width: '250px'}}/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
