import * as React from "react";
const SvgLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"

    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 832.33 134.77"
    {...props}
  >

    <defs>
      <font
        id="FontID1"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={700}
        horizAdvX={765}
        style={{
          fillRule: "nonzero",
        }}
      >
        <fontFace fontFamily="Montserrat Bold">
          <fontFaceSrc>
            <fontFaceName name="Montserrat Bold" />
          </fontFaceSrc>
        </fontFace>
        <missingGlyph>
          <path d="M0 0z" />
        </missingGlyph>
        <glyph
          d="M612.001 570.001v129.997h-529V0h161.998v254.998h324.003v130.003H244.999v185h367.002z"
          horizAdvX={638}
          unicode="F"
        />
        <glyph
          d="M421.999-11.997c219.999 0 383.003 152.999 383.003 362 0 209-163.004 362-383.003 362-220.998 0-382.996-154.004-382.996-362 0-208.002 161.998-362 382.996-362zm0 137.997c-125.001 0-219 91-219 224.002 0 132.995 93.999 223.996 219 223.996s219-91.001 219-223.996C640.999 217 547 126 421.999 126z"
          horizAdvX={843}
          unicode="O"
        />
        <glyph
          d="M312.998-11.997c200.001 0 297.004 99.994 297.004 216.83 0 256.997-407.004 167.83-407.004 296.999 0 44.168 37.004 80.166 133.001 80.166 61.998 0 128.998-17.999 193.999-54.997l50 122.996c-65.001 41.001-154.997 62.004-243 62.004-199.003 0-295-99.001-295-218.17 0-260 406.997-169.999 406.997-301C448.995 150 410 118 313.995 118c-84 0-171.997 30.002-230.997 72.832L27.995 68.999C90 20.997 201.997-11.997 312.996-11.997z"
          horizAdvX={638}
          unicode="S"
        />
        <glyph
          d="M227.999 0h161.998v568.003h224.002v131.996H3.996V568.003h224.002V0z"
          horizAdvX={618}
          unicode="T"
        />
      </font>
      <font
        id="logo_svg__FontID0"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight={900}
        horizAdvX={806}
        style={{
          fillRule: "nonzero",
        }}
      >
        <fontFace fontFamily="Montserrat Black">
          <fontFaceSrc>
            <fontFaceName name="Montserrat Black" />
          </fontFaceSrc>
        </fontFace>
        <missingGlyph>
          <path d="M0 0z" />
        </missingGlyph>
        <glyph horizAdvX={300} />
        <glyph
          d="M511.001 699.998V452.001h-218v247.997H57.002V0h235.999v258.002h218V0H747v699.998H511.001z"
          horizAdvX={804}
          unicode="H"
        />
        <glyph
          d="M394.001 699.998H57.002V0h235.999v171.997h101c194.999 0 319 102.005 319 264.004 0 161.998-124.002 263.997-319 263.997zM379 353.999H293v164.003H379c65.001 0 96.003-31.002 96.003-82.001 0-51-31.002-82.002-96.003-82.002z"
          horizAdvX={742}
          unicode="P"
        />
      </font>
      <style>
        {
          '@font-face{font-family:"Montserrat";font-variant:normal;font-style:normal;font-weight:700;src:url(#logo_svg__FontID1)format(svg)}' +
            '@font-face{font-family:"Montserrat Black";font-variant:normal;' +
            'font-style:normal;font-weight:900;src:url(#logo_svg__FontID0)format(svg)}.logo_svg__fil0{fill:#727271}' +
            '.logo_svg__fil1{fill:#60cc29}.logo_svg__fnt0{font-weight:900;font-size:161.58px}'
        }
      </style>
    </defs>
    <g id="logo_svg__Layer_x0020_1">
      <g id="logo_svg___2034936984528">
        <text x={-9.21} y={123.33} className="logo_svg__fil0 logo_svg__fnt0">
          {"H"}
        </text>
        <text x={257.73} y={123.33} className="logo_svg__fil0 logo_svg__fnt0">
          {"P "}
        </text>
        <text
          x={389.59}
          y={123.33}
          className="logo_svg__fil1"
          style={{
            fontWeight: 700,
            fontSize: "161.58px",
            fontFamily: "&quot",
          }}
        >
          {"SOFT"}
        </text>
        <path
          d="M177.07 81.93c-3.35 10.25-12.26 19.01-28.3 24.69-4.72-8.04-7.46-16.14-8.01-23.92 10.07-2.09 19.7-6.26 26.87-13.78 2.35 4.77 5.51 9.1 9.45 13.02zm33.14-36.03c-.11 15.44-5.97 29.06-21.58 39.61-15.61-10.55-21.47-24.17-21.58-39.61 4.12-2.37 7.92-5.43 11.26-9.29 4.97-5.75 8.3-12.55 10.32-19.76 2.02 7.21 5.34 14.01 10.32 19.76 3.34 3.87 7.14 6.93 11.26 9.29zM186.55-.01c-.91 29.09-18.41 52.04-56.18 47.48 2.7-27.5 21.75-47.42 56.18-47.48zm4.16 0c.91 29.09 18.41 52.04 56.17 47.48-2.7-27.5-21.75-47.42-56.17-47.48zm9.48 81.93c3.35 10.25 12.26 19.01 28.3 24.69 4.72-8.04 7.46-16.14 8.01-23.92-10.07-2.09-19.7-6.26-26.87-13.78-2.35 4.77-5.51 9.1-9.45 13.02z"
          className="logo_svg__fil1"
        />
        <path
          d="M218.21 107.2c-7.04 10.39-18.17 20.91-29.59 27.57-11.42-6.67-22.55-17.18-29.59-27.57 9.49-4.79 17.7-11.8 21.67-21.95 1.93 1.61 4.01 3.15 6.23 4.61l.09.06.08.06.08.05 1.43.93 1.43-.93.08-.05.08-.06.09-.06c2.22-1.46 4.3-2.99 6.23-4.61 3.97 10.14 12.18 17.16 21.67 21.95zm-6.61-42.88c6.7 7.89 17.55 13.4 33.3 15.1 2.19-9.68 2.06-18.9-.51-27.1-10.27.87-20.58-.18-29.69-4.15-.24 5.82-1.28 11.2-3.1 16.15zm-45.95 0c-6.7 7.89-17.55 13.4-33.3 15.1-2.19-9.68-2.06-18.9.51-27.1 10.27.87 20.58-.18 29.69-4.15.24 5.82 1.28 11.2 3.1 16.15z"
          className="logo_svg__fil0"
        />
      </g>
    </g>
  </svg>
);
export default SvgLogo;

