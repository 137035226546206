import React from 'react';
import {Grid} from '@mui/material';
import contactUs from '../assets/contact_pic.png';
import {EmailForm} from './EmailForm';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SvgContactIconText from './svg/SvgContactIconText';

export const RequestQuote = () => {
	return (
		<Container maxWidth="xl">
			<div id={'mainPanel'}/>
			<Grid container paddingTop={3}>
				<Grid item xs={0} md={4}
				      sx={{display: {xs: 'none', md: 'flex'}}}
				      paddingLeft={3} paddingRight={3}>
					<img
						src={contactUs}
						alt="Contact Us"
						style={{width: "100%", height: "100%"}}
					/>
				</Grid>
				<Grid item xs={12} md={8} paddingLeft={2}>
					<Grid container>
						<Grid item xs={1} paddingTop={4}/>
						<Grid item xs={11}>
							<SvgContactIconText/>
						</Grid>
						<Grid item xs={12} md={12} paddingBottom={2}>
							<Typography variant="body1">
								Request a quote for your project. We will get back to you within 24 hours.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} paddingBottom={2}>
							<EmailForm reason={'QUOTE'}/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
