import React, {useEffect, useState} from 'react';
import {Alert, FormGroup, Snackbar, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {sendEmailContactForm} from '../services/mailSender';
import LoadingButton from '@mui/lab/LoadingButton'

export const EmailForm = ({reason}) => {
	const [formValues, setFormValues] = useState({});
	const [showSuccess, setShowSuccess] = useState(false);
	const [showError, setShowError] = useState(false);
	const [sending, setSending] = useState(false);
	const [submitEnabled, setSubmitEnabled] = useState(true);
	const [formErrors, setFormErrors] = useState({name: false, email: false, message: false});
	const [formReady, setFormReady] = useState({name: false, email: false, message: false});

	useEffect(() => {
		for (let key in formReady) {
			if (formErrors[key] || !formReady[key]) {
				setSubmitEnabled(false);
				return;
			}
		}
		setSubmitEnabled(true);
	}, [formReady, formErrors, formValues]);

	const handleTextChange = (e, key) => {
		if (key==='email') {
			const emailRegex =  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
			if (!emailRegex.test(e.target.value)) {
				setFormErrors({...formErrors, [key]: true});
				setFormReady({...formReady, [key]: false});
			} else {
				setFormErrors({...formErrors, [key]: false});
				setFormReady({...formReady, [key]: true});
			}
		} else {
			if (e.target.value.length === 0) {
				setFormErrors({...formErrors, [key]: true});
				setFormReady({...formReady, [key]: false});
			} else {
				setFormErrors({...formErrors, [key]: false});
				setFormReady({...formReady, [key]: true});
			}
		}
		setFormValues({...formValues, [key]: e.target.value});
	};

	const handleSubmit = async () => {
		setSending(true);
		const res = await sendEmailContactForm(formValues.name, formValues.email, formValues.message, reason);
		if (res) {
			setFormValues({});
			setShowSuccess(true);
		} else {
			setShowError(true);
		}
		setSending(false);
	}

	const handleAlertClose = () => {
		setShowSuccess(false);
		setShowError(false);
	}

	return (
		<form>
			<FormGroup>
				<TextField
					sx={{paddingBottom: 2}}
					name="name"
					value={formValues.name || ''}
					variant="outlined"
					placeholder="Name..."
					label="Name"
					required
					error={formErrors.name}
					onChange={(e) => handleTextChange(e, 'name')}
				/>
				<TextField
					sx={{paddingBottom: 2}}
					name="email"
					value={formValues.email || ''}
					variant="outlined"
					placeholder="Email..."
					required
					label="Email"
					error={formErrors.email}
					onChange={(e) => handleTextChange(e, 'email')}
				/>
				<TextField
					sx={{paddingBottom: 2}}
					name="mesage"
					value={formValues.message || ''}
					variant="outlined"
					placeholder="Type your message here..."
					multiline={true}
					rows={6}
					required
					error={formErrors.message}
					label="Type your message here..."
					onChange={(e) => handleTextChange(e, 'message')}
				/>
				<Snackbar
					anchorOrigin={{vertical: 'top', horizontal: 'right'}}
					autoHideDuration={20000}
					open={showSuccess}
					onClose={handleAlertClose}
				>
					<Alert
						onClose={handleAlertClose}
						severity="success" sx={{width: '100%'}}>
						Thank you for contacting us, someone will be in touch shortly.
					</Alert>
				</Snackbar>
				<Snackbar
					anchorOrigin={{vertical: 'top', horizontal: 'right'}}
					autoHideDuration={20000}
					open={showError}
					onClose={handleAlertClose}
				>
					<Alert
						onClose={handleAlertClose}
						severity="error" sx={{width: '100%'}}>
						Seems we could not send your message, we are sorry for inconvenience you may try to send us email instead.
					</Alert>
				</Snackbar>
				<LoadingButton
					variant="outlined"
					loadingPosition="start"
					loading={sending}
					disabled={!submitEnabled}
					startIcon={<SendIcon />}
					onClick={handleSubmit}>
					Submit
				</LoadingButton>
			</FormGroup>
		</form>
	);
};
