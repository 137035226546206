import React from 'react';
import {Grid} from '@mui/material';
import aboutUs from '../assets/about_photo.png';
import SvgAboutUs from './svg/SvgAboutUs';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export const AboutUs = () => {
	return (
		<Container maxWidth="xl">
			<div id={'mainPanel'}/>
			<Grid container paddingTop={4}>
				<Grid item xs={4}
				      sx={{display: {xs: 'none', md: 'flex'}}}
				      paddingLeft={3} paddingRight={3}>
					<img
						src={aboutUs}
						alt="About Us"
						style={{width: "100%", height: "100%"}}
					/>
				</Grid>
				<Grid item xs={8}>
					<Grid container>
						<Grid item xs={1} paddingTop={4}/>
						<Grid item xs={11}>
							<SvgAboutUs/>
						</Grid>
						<Grid item xs={12} paddingTop={4} paddingLeft={3}>
							<Typography>
								Hop Soft Pty Ltd is one of the leading software developers in the automation industry.
								We develop a wide variety of drivers, plugins, modules and apps. Our aim is to deliver
								feature rich, affordable, documented and well tested products. All our developers are
								industry experts with more than 10 years of experience in the field. We offer a variety
								of services such as contract work for manufacturers, co-funded contracts for integrators
								and also complete projects funded by us.
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
