import * as React from "react";
const SvgContactIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 5.82 5.82"
    {...props}
  >
    <circle
      cx={2.91}
      cy={2.91}
      r={2.74}
      style={{
        fill: "none",
        stroke: "#60cc29",
        strokeWidth: 0.33,
        strokeMiterlimit: 22.9256,
      }}
    />
    <path
      d="M2.25 2.17h1.91l-1.04.82c-.02.02-.05.03-.07.03-.02 0-.05-.01-.07-.03l-.74-.58c-.01-.01-.02-.01-.03-.01.04-.05.06-.11.05-.17v-.05zm-.48-.53s.01 0 .01.01c.02.01.04.03.07.06.05.06.11.15.13.2v.01c.08.13.11.24.11.3s-.02.09-.07.12l-.14.08c-.09.05-.14.15-.15.24-.01.09.01.17.04.24l.35.6c.04.07.11.13.19.16.08.04.19.05.28-.01l.14-.08c.05-.03.09-.03.14 0 .06.03.13.11.21.25v.01c.04.05.09.14.11.22.01.04.02.07.02.09 0 .02 0 .02-.01.02l-.11.06c-.27.12-.52.09-.74-.02a1.44 1.44 0 0 1-.55-.57l-.29-.51c-.15-.24-.23-.51-.22-.76.01-.25.11-.47.35-.65l.11-.06h.01zm0-.15c-.03 0-.06.01-.08.02l-.11.07h-.01c-.27.2-.4.48-.41.77-.01.29.08.58.24.84l.29.51c.15.27.36.5.61.63.26.13.56.16.87.03h.01l.11-.07c.05-.03.08-.09.08-.14 0-.05-.01-.1-.02-.15a.734.734 0 0 0-.13-.25c-.08-.14-.16-.24-.26-.3a.272.272 0 0 0-.3 0l-.14.08a.17.17 0 0 1-.14 0 .306.306 0 0 1-.12-.1l-.35-.6a.306.306 0 0 1-.03-.15c.01-.05.03-.1.07-.12l.14-.08c0 .02.01.04.03.05l.74.58c.05.04.11.06.16.06h.01c.06 0 .11-.02.16-.06l1.03-.81v1.33c0 .02-.02.05-.07.05h-.71c-.04 0-.08.03-.08.08 0 .04.03.08.08.08h.71c.12 0 .22-.08.22-.2V2.22c0-.12-.11-.2-.22-.2H2.19a.947.947 0 0 0-.09-.17.999.999 0 0 0-.16-.24.273.273 0 0 0-.12-.09.188.188 0 0 0-.08-.02z"
      style={{
        fill: "#60cc29",
        stroke: "#60cc29",
        strokeWidth: 0.09,
        strokeLinejoin: "round",
        strokeMiterlimit: 22.9256,
      }}
    />
  </svg>
);
export default SvgContactIcon;

