import React from 'react';

import house from '../assets/house.png';
import mobileHouse from '../assets/web_telephone.png';
import hand from '../assets/hand.png';
import SvgSlogan from '../components/svg/SvgSlogan';
import Box from '@mui/material/Box';

export const TopBanner = () => {

	return (
		<div>
			<Box sx={{display: {xs: 'none', md: 'flex'}}}>
				<div style={{position: "relative", width: "100%"}}>
					{/* Base image */}
					<img
						src={house}
						alt="House"
						style={{width: "100%", height: "100%"}}

					/>
					{/* Overlay image */}
					<img
						src={hand}
						alt="Hand"
						style={{
							position: "absolute",
							top: -20,
							right: 0,
							width: "30%"

						}}
					/>
					<SvgSlogan style={{
						position: "absolute",
						top: 40,
						left: 90,

					}} width={'25%'}/>
				</div>
			</Box>
			<Box sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}>
				<img
					src={mobileHouse}
					alt="House"
					style={{width: "100%", height: "100%"}}

				/>
			</Box>
		</div>
	);
};
