export const TAB_SELECTED = 'TAB_SELECTED';
export const PAGES = 'PAGES';
export const DRIVER_CATEGORIES = 'DRIVER_CATEGORIES';
export const DRIVER_CATEGORIES_SELECTED = 'DRIVER_CATEGORIES_SELECTED';
export const selectTab = tab => {
	return{
		type: TAB_SELECTED,
		payload: tab
	};
}

export const selectCategory = category => {
	return{
		type: DRIVER_CATEGORIES_SELECTED,
		payload: category
	};
}
