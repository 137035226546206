import React, {useEffect} from 'react';
import {Box, Grid, Tooltip, Select} from '@mui/material';
import DynamicIcon from './DynamicIcon';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {DriverCard} from './DriverCard';
import {connect} from 'react-redux';
import {getDrivers} from '../store/actions/apiCalls';
import IconButton from '@mui/material/IconButton';
import {HashLink} from 'react-router-hash-link';
import MenuItem from '@mui/material/MenuItem';

const PortfolioBrowser = ({drivers, getDrivers, categories}) => {
	const {platform = 'all', category = 'all', driverId} = useParams();
	useEffect(() => {
		getDrivers('/api/v1/drivers', {platform, category, driverId});
	}, [platform, category, driverId, getDrivers, categories]);

	return (
		<Grid container>
			<Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}} paddingTop={'10px'}>
				<div id={'portfolioBrowser'}/>
				<div id={'mainPanel'}/>
				<Box sx={{
					borderRadius: 5,
					backgroundColor: 'primary.main',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					// gap: '8px',
					paddingLeft: 2,
					width: '600px',
					height: '45px'
				}}>
					<Grid container>
						<Grid item xs={2} sm={1} sx={{display:'flex', alignItems:'center'}}>
							<DynamicIcon name={category} style={{fontSize: '37px'}}/>
						</Grid>
						<Grid item xs={6} sm={5} sx={{display:{xs:'none', sm:'flex'}, alignItems:'center'}}>
							<Typography color={'primary.contrastText'} variant={'h6'} textTransform={'uppercase'}
							            paddingRight={5}>
								{category === 'all' ? 'All Categories' : category}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={5} sx={{display:{xs:'flex', sm:'none'}, alignItems:'center'}}>
							<Select
								labelId="category-select-label"
								id="category-select"
								value={category}
								onChange={(e) => {console.log(e.target.value)}}
								label="Category"
								variant="standard"
								size={'small'}
								sx={{width: '100%'}}
							>
								<MenuItem value={'all'}>All</MenuItem>
								{categories.map((cat, index) => (
									<MenuItem key={index} value={cat.value} component={HashLink} smooth to={`/portfolio/${platform}/${cat.value}#portfolioBrowser`}>{cat.name}</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={0} sm={6} sx={{display:{xs:'none', sm:'flex'}}} >
							<Box sx={{display:{xs:'none', sm:'flex'}}} >
							{categories.filter((cat) => (cat.value !== category)).map((cat, index) => (
								<Tooltip title={cat.name} key={index}>
									<IconButton key={index} component={HashLink}
									            to={`/portfolio/${platform}/${cat.value}#portfolioBrowser`}>
										<DynamicIcon name={cat.value} style={{fontSize: '33px'}}/>
									</IconButton>
								</Tooltip>
							))}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12} paddingTop={'10px'}>
				{drivers.map((driver, index) => (
					<Box key={index}
					     sx={{display: 'flex', justifyContent: 'center', paddingBottom: 5}}>
						<DriverCard key={index} driver={driver}/>
					</Box>
				))}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (state) => {
	return {
		drivers: state.drivers.driverList,
		categories: state.drivers.driverCategories,
	};
};

export default connect(mapStateToProps, {getDrivers})(PortfolioBrowser);


// <div style={{position: "sticky", top: 0, right: 80}}>
// 	<Stack>
// 		{categories.filter((cat) => (cat.value !== category)).map((cat, index) => (
// 			<Tooltip title={cat.name} key={index}>
// 				<IconButton hint={'hint'} key={index} component={HashLink}
// 				            to={`/portfolio/${platform}/${cat.value}#portfolioBrowser`}>
// 					<DynamicIcon name={cat.value} style={{fontSize: '35px'}}/>
// 				</IconButton>
// 			</Tooltip>
// 		))}
// 	</Stack>
// </div>
