
export const pagesList = [
	{value:0, name:'Home', path:'/'},
	{value:1, name:'Request a Quote', path: '/quote'},
	{value:2, name:'Our Portfolio', path:'/portfolio'},
	{value:3, name:'Contact Us', path:'/contactus'}
];

export const platformsList = [
	{value:'control4', name:'Control4'},
	{value:'rti', name:'RTI'},
	{value:'elan', name:'Elan'}
];

export const driverCategoriesList = [
	{value:'lighting', name:'Lighting'},
	{value:'security', name:'Security'},
	{value:'climate', name:'Climate'},
	{value:'audiovisual', name:'Audiovisual'},
	{value:'weather', name:'Weather'},
	{value:'utilities', name:'Utilities'}
];

export const portfolioList = {
	'control4': {
		'lighting': [{
			title:'Dynalite',
			name: 'Dynalite',
			path: '/portfolio/control4/lighting/dynalite',
			img: '/assets/control4/dynalite.jpg',
			text:'This Dynalite driver for Control4, supports many features, and supports many of the ways in which Dynalite is programmed. It can communicate with Dynalite through RS-232 or IP communication. To use this driver, you will need to know the Areas/Channels/Presets used by the Dynalite system. If they are not known, you can find them out through the Debug of the driver, but this will require much more installation time. The preferred way is to get this information of the Dynalite System Programmer.'
		},
			{
				title:'Fibaro / Zooz / Wenture Wise / Yubii',
				name: 'Fibaro / Zooz / Wenture Wise / Yubii',
				path: '/portfolio/control4/lighting/fibaro',
				img: '/assets/control4/fibaro.jpg',
				text:'Driver allows for integration of Fibaro Home Centrer 3/Lite. It imlements latest Control4 light color proxy, you can now combine simple control of all your RGB/CCT lights to the rest of your Control4 system.'
			}
			],
		'security': [],
		'climate': [],
		'audiovisual': [],
		'weather': [],
		'utilities': []
	},
	'rti': {
		'lighting': [],
		'security': [],
		'climate': [],
		'audiovisual': [],
		'weather': [],
		'utilities': []
	},
	'elan': {
		'lighting': [],
		'security': [],
		'climate': [],
		'audiovisual': [],
		'weather': [],
		'utilities': []
	}
}
