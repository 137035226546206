import {createTheme} from '@mui/material';
// import Montserrat from '../fonts/Montserrat-Regular.ttf';

export default createTheme({
	typography: {
		allVariants: {
			fontFamily: [
				'Montserrat'
			].join(','),
			textTransform: "none",
		},
		subtitle2: {
			fontWeight: 600,
		},
		caption: {
			fontSize: '0.6rem',
		}
	},
	palette: {
		primary: {
			main: '#60cc29',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#ffffff',
		},
		text:{
			primary: '#736f6f',
		}
	}
});
