import React from 'react';

import {TopBanner} from '../components/TopBanner';
import {Divider, Grid, Box} from '@mui/material';
import HsAppBar from '../components/HsAppBar';
import shadow from '../assets/shadow.png';
import {connect} from 'react-redux';
import {selectCategory, selectTab} from '../store/actions/navigation';
import {Outlet} from "react-router-dom";
import Footer from '../components/Footer';
import {PortfolioPanel} from '../components/PortfolioPanel';


class Main extends React.Component {
	componentDidMount() {

	}

	render() {
		return (
			<Box >
				<Divider sx={{borderTop: '7px solid #60cc29'}}/>
				<HsAppBar/>
				<TopBanner/>
				<Grid container>
					{/*<Grid item xs={12} md={12} style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>*/}
					{/*	<GreyIcons/>*/}
					{/*</Grid>*/}
					{/*<Grid item xs={12} md={6} style={{display: 'flex', justifyContent: "center", alignItems: "center"}}>*/}
					{/*	<img*/}
					{/*		src={logos}*/}
					{/*		alt="Logos"*/}
					{/*		style={{width: "60%", height: "auto"}}*/}
					{/*	/>*/}
					{/*</Grid>*/}
					<Grid item xs={12} md={12} style={{display: 'flex', alignContent: "center", alignItems: "center"}}>
						<img
							src={shadow}
							alt="Shadow"
							style={{width: "100%", height: "100%"}}
						/>
					</Grid>
					<Grid item xs={12} md={12} style={{display: 'flex', alignContent: "center", alignItems: "center"}}>
						<PortfolioPanel/>
					</Grid>
					<Grid item xs={12} md={12} style={{display: 'flex', alignContent: "center", alignItems: "center"}}>
						<Outlet/>
					</Grid>
					<Grid item xs={12} md={12}>
						<Footer/>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		tabSelected: state.navigation.tabSelected,
	};
};

export default connect(mapStateToProps, {selectTab, selectCategory})(Main);
