import React, {useEffect} from 'react';
import {Grid, Link} from '@mui/material';
import contactUs from '../assets/contact_pic.png';
// import {EmailForm} from './EmailForm';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import SvgContactIconText from './svg/SvgContactIconText';

export const ContactUs = () => {

	useEffect(() => {
		const script = document.createElement('script');
		script.id = '21211fbf-4bb5-438f-a2c3-a2df85151da7';
		script.setAttribute('data-yt-url', 'https://hopsoft.youtrack.cloud');
		script.src = 'https://hopsoft.youtrack.cloud/static/simplified/form/form-entry.js';
		script.setAttribute('data-theme', 'light');
		script.setAttribute('data-lang', 'en');

		document.getElementById("youtrack-script").appendChild(script);

		return () => {
			const scriptElement = document.getElementById('21211fbf-4bb5-438f-a2c3-a2df85151da7');
			if (scriptElement) {
				scriptElement.remove();
			}
		};
	}, []);
	return (
		<Container maxWidth="xl">
			<div id={'mainPanel'}/>
			<Grid container paddingTop={3}>
				<Grid item xs={0} md={4}
				      sx={{display: {xs: 'none', md: 'flex'}}}
				      paddingLeft={3} paddingRight={3}>
					<img
						src={contactUs}
						alt="Contact Us"
						style={{width: "100%", height: "100%"}}
					/>
				</Grid>
				<Grid item xs={12} md={8} paddingLeft={2}>
					<Grid container>
						<Grid item xs={1} paddingTop={4}/>
						<Grid item xs={12} md={12} paddingBottom={2}>
							<Typography variant="body1">
								For technical support of our products please email us on <Link
								variant="body2"
								color="text.primary"
								href={`mailto:support@hopsoft.com.au`}
								underline="hover">
								support@hopsoft.com.au
							</Link> or use the form below.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} paddingBottom={2}>
							<div id="youtrack-script">
								{/* This is where the form will be inserted */}
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
