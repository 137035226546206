import {combineReducers} from 'redux';
import {navigation, pages, driverCategories, driverCategorySelected} from './navigation';
import {drivers} from './apiReducers';

export default combineReducers({
	navigation: navigation,
	pages: pages,
	drivers: combineReducers({driverCategories:
		driverCategories,
		categorySelected: driverCategorySelected,
		driverList: drivers
	}),

});
