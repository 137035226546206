import * as React from "react";
const SvgControl4Logo = (props) => (
    <svg {...props} viewBox="0 0 60 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.00177027 6.87399V6.85275C0.00177027 4.66646 1.71717 2.85547 4.10526 2.85547C5.57813 2.85547 6.45796 3.35292 7.27051 4.0805L6.64383 4.72134C5.96051 4.10351 5.19398 3.66094 4.09287 3.66094C2.29604 3.66094 0.950637 5.05238 0.950637 6.82973V6.85275C0.950637 8.64072 2.30667 10.0445 4.09287 10.0445C5.20637 10.0445 5.93573 9.63562 6.71288 8.92928L7.31654 9.49222C6.47035 10.3101 5.54273 10.85 4.06986 10.85C1.72779 10.85 0 9.09391 0 6.87399H0.00177027Z" fill="#898989"/>
      <path d="M14.4134 7.89087V7.86786C14.4134 6.64283 13.4504 5.63731 12.1864 5.63731C10.8888 5.63731 9.99482 6.64106 9.99482 7.84485V7.86609C9.99482 9.09289 10.9455 10.086 12.2094 10.086C13.507 10.086 14.4117 9.09289 14.4117 7.8891L14.4134 7.89087ZM9.08136 7.89087V7.86786C9.08136 6.25514 10.4038 4.88672 12.2112 4.88672C14.008 4.88672 15.3287 6.2339 15.3287 7.84662V7.86786C15.3287 9.48058 13.9956 10.8508 12.1864 10.8508C10.3896 10.8508 9.07959 9.50359 9.07959 7.89087H9.08136Z" fill="#898989"/>
      <path d="M17.354 5.00905H18.2462V6.00394C18.641 5.38612 19.2659 4.88867 20.298 4.88867C21.7478 4.88867 22.594 5.81629 22.594 7.17409V10.7182H21.7018V7.38299C21.7018 6.32259 21.0981 5.66051 20.0448 5.66051C19.0128 5.66051 18.248 6.37747 18.248 7.44849V10.7164H17.3558V5.00728L17.354 5.00905Z" fill="#898989"/>
      <path d="M25.462 9.20416V5.7592H24.6265V5.00861H25.462V3.28613H26.3543V5.00861H28.2555V5.7592H26.3543V9.0944C26.3543 9.79012 26.7596 10.045 27.3633 10.045C27.6643 10.045 27.9192 9.99016 28.2325 9.84499V10.5743C27.9192 10.7284 27.5828 10.8169 27.1544 10.8169C26.1914 10.8169 25.462 10.3637 25.462 9.20416Z" fill="#898989"/>
      <path d="M30.2471 5.00839H31.1393V6.49896C31.5801 5.55009 32.4493 4.865 33.5858 4.90925V5.82626H33.5168C32.2068 5.82626 31.1393 6.72024 31.1393 8.44272V10.7175H30.2471V5.00839Z" fill="#898989"/>
      <path d="M40.0628 7.89087V7.86786C40.0628 6.64283 39.0998 5.63731 37.8376 5.63731C36.5382 5.63731 35.646 6.64106 35.646 7.84485V7.86609C35.646 9.09289 36.5966 10.086 37.8606 10.086C39.1582 10.086 40.0628 9.09289 40.0628 7.8891V7.89087ZM34.7308 7.89087V7.86786C34.7308 6.25514 36.0514 4.88672 37.8606 4.88672C39.6574 4.88672 40.9781 6.2339 40.9781 7.84662V7.86786C40.9781 9.48058 39.6451 10.8508 37.8358 10.8508C36.039 10.8508 34.729 9.50359 34.729 7.89087H34.7308Z" fill="#898989"/>
      <path d="M43.085 10.7181H43.9772V2.65625H43.085V10.7181Z" fill="#898989"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M50.4105 8.44668L54.822 8.44845V11.1977H56.1745V7.3137L51.9312 7.31547L55.5301 2.34277H53.8484L50.0972 7.558L50.4105 8.44668Z" fill="#C42732"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M55.0929 12.9642C54.5476 13.0598 54.2024 13.0846 53.7191 13.0846C50.3078 13.0846 47.5427 10.3194 47.5427 6.90807C47.5427 3.49675 50.3078 0.731586 53.7191 0.731586C56.5268 0.731586 58.8954 2.60276 59.6478 5.16789C59.2566 3.43833 58.182 1.8681 56.5374 0.905073C53.3881 -0.939552 49.3147 0.126153 47.4701 3.27724C45.6255 6.42655 46.6912 10.5 49.8405 12.3446C51.4868 13.3076 53.3934 13.4705 55.0929 12.9659V12.9642Z" fill="#C42732"/>
      <path d="M58.9941 1.50684V1.61836H59.2101V2.18131H59.3447V1.61836H59.5606V1.50684H58.9959H58.9941ZM60.4228 2.18131L60.3714 1.50684H60.1785L60.0616 1.83965C60.0457 1.88921 60.0315 1.94586 60.0138 2.00782H60.012C59.9943 1.93878 59.982 1.88921 59.9678 1.83611L59.851 1.50684H59.6509L59.6013 2.18131H59.7306L59.7554 1.78477C59.7571 1.72281 59.7607 1.65731 59.7607 1.59535H59.7677C59.7819 1.65554 59.8032 1.73343 59.8155 1.78654L59.9377 2.17069H60.0775L60.1997 1.79716C60.2192 1.7352 60.2386 1.65554 60.2563 1.59535H60.2634C60.2652 1.66793 60.2652 1.73166 60.2687 1.78477L60.2935 2.18131H60.4281H60.4228Z" fill="#898989"/>
    </svg>
);
export default SvgControl4Logo;

