import React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from './theme/index';
import Main from './pages/Main';
import 'typeface-montserrat';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AboutUs} from './components/AboutUs';
import {ContactUs} from './components/ContactUs';
import PortfolioBrowser from './components/PortfolioBrowser';
import {RequestQuote} from './components/RequestQuote';

function App() {
  return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main/>}>
                        <Route index element={<AboutUs/>}/>
                        <Route path="/aboutus" element={<AboutUs/>}/>
                        <Route path="/portfolio/:platform?/:category?/:driverId?" element={<PortfolioBrowser/>}/>
                        <Route path="/quote" element={<RequestQuote/>}/>
                        <Route path="/contactus" element={<ContactUs/>}/>
                        <Route path="*" element={<div>Error</div>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </React.Fragment>
      </ThemeProvider>
  );
}

export default App;
