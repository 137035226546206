import * as React from "react";
const SvgAboutUs = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={153.019}
    height={25.053}
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 208.55 34.14"
    {...props}
  >
    <defs>
      <font
        id="aboutus_svg__FontID1"
        fontVariant="normal"
        fontWeight={400}
        horizAdvX={716}
        style={{
          fillRule: "nonzero",
        }}
      >
        <fontFace fontFamily="Montserrat">
          <fontFaceSrc>
            <fontFaceName name="Montserrat Regular" />
          </fontFaceSrc>
        </fontFace>
        <missingGlyph>
          <path d="M0 0z" />
        </missingGlyph>
        <glyph
          d="M396.002-6.007C574.997-6.007 683.009 98 683.009 299.005v400.99h-72.002V302.01c0-166.014-78.01-242.003-215.005-242.003-135.994 0-214.003 75.989-214.003 242.003v397.987h-74.004V299.005C107.995 98 217.008-6.007 396.003-6.007z"
          horizAdvX={792}
          unicode="U"
        />
        <glyph
          d="M240.001-5.006c136.995 0 217.007 59.003 217.007 150.01 0 201.83-341.004 94.82-341.004 236.998 0 49.992 42 87.003 136.995 87.003 53.997 0 108.995-12.999 155-45.004l31.003 57.001C395 511.005 320.995 529.993 252.999 529.993c-133.99 0-207.995-63.992-207.995-149.994 0-207.996 341.004-100.16 341.004-236.997 0-51.995-40.999-86.002-141-86.002-74.005 0-145.005 25.997-187.005 59.003l-32.005-56c43-37.01 127-65.01 214.003-65.01z"
          horizAdvX={488}
          unicode="s"
        />
      </font>
      <font
        id="aboutus_svg__FontID0"
        fontVariant="normal"
        fontWeight={700}
        horizAdvX={766}
        style={{
          fillRule: "nonzero",
        }}
      >
        <fontFace fontFamily="Montserrat">
          <fontFaceSrc>
            <fontFaceName name="Montserrat Bold" />
          </fontFaceSrc>
        </fontFace>
        <missingGlyph>
          <path d="M0 0z" />
        </missingGlyph>
        <glyph horizAdvX={283} />
        <glyph
          d="M606 0h170.001L462.997 699.995H302.991L-8.994 0h165.996l61.99 149.994h325L606 0zM271.004 273.007l110.998 268 110.998-268H271.004z"
          horizAdvX={766}
          unicode="A"
        />
        <glyph
          d="M228.003 0h161.991v568.006h223.998v131.989H4.004V568.006h223.998V0z"
          horizAdvX={617}
          unicode="T"
        />
        <glyph
          d="M389.995 545.996c-66.996 0-121.994-20.991-161.991-64.993v260.992H72.003V0h148.993v62.007c39.013-47.006 96.998-70 169-70 151.013 0 268 107.994 268 276.994 0 169-116.988 276.994-268 276.994zm-26.998-426.004c-77.992 0-136.995 56.017-136.995 149.01s59.003 148.993 136.995 148.993c78.009 0 137.013-56 137.013-148.993s-59.004-149.01-137.013-149.01z"
          horizAdvX={689}
          unicode="b"
        />
        <glyph
          d="M328.005-7.992c171.002 0 294.999 114.985 294.999 276.994 0 161.99-123.997 276.994-294.999 276.994s-296-115.003-296-276.994c0-162.01 124.998-276.994 296-276.994zm0 127.984c-78.01 0-137.996 56.017-137.996 149.01s59.987 148.993 137.996 148.993c77.992 0 136.995-56 136.995-148.993s-59.003-149.01-136.995-149.01z"
          horizAdvX={654}
          unicode="o"
        />
        <glyph
          d="M457.992 538.003V272.005c0-99-51.995-145.005-123.997-145.005C264.997 127 225 166.997 225 257.004v280.999H68.999v-304.01c0-165.996 94.996-241.986 230.006-241.986 65.995 0 125.999 24.996 166.997 71.984V0h147.991v538.003H457.992z"
          horizAdvX={686}
          unicode="u"
        />
      </font>
      <style>
        {
          '@font-face{font-family:"Montserrat";font-variant:normal;font-weight:400;src:url(#aboutus_svg__FontID1)format(svg)}@font-face{font-family:"Montserrat";font-variant:normal;font-weight:700;src:url(#aboutus_svg__FontID0)format(svg)}.aboutus_svg__fil0{fill:#727271}.aboutus_svg__fnt0,.aboutus_svg__fnt1{font-weight:400;font-size:29.32px;font-family:"Montserrat"}.aboutus_svg__fnt0{font-weight:700}'
        }
      </style>
    </defs>
    <g id="aboutus_svg__Layer_x0020_1">
      <text x={49.49} y={27.34} className="aboutus_svg__fil0 aboutus_svg__fnt0">
        {"A"}
      </text>
      <text
        x={142.25}
        y={27.34}
        className="aboutus_svg__fil0 aboutus_svg__fnt0"
      >
        {"T "}
      </text>
      <text x={71.95} y={27.34} className="aboutus_svg__fil0 aboutus_svg__fnt0">
        {"BOU"}
      </text>
      <text
        x={168.67}
        y={27.34}
        className="aboutus_svg__fil0 aboutus_svg__fnt1"
      >
        {"U"}
      </text>
      <text
        x={191.89}
        y={27.34}
        className="aboutus_svg__fil0 aboutus_svg__fnt1"
      >
        {"S"}
      </text>
      <g id="aboutus_svg___2787281831600">
        <path
          d="M26.45 16.2a5.134 5.134 0 0 0-3.61-8.78c-2.18 0-4.11 1.38-4.84 3.41-.3-.06-.61-.09-.93-.09-.32 0-.63.03-.93.09a5.134 5.134 0 1 0-8.45 5.37c-2.26 1.29-3.71 3.72-3.71 6.36 0 .46.37.83.83.83.46 0 .83-.37.83-.83a5.68 5.68 0 0 1 3.64-5.29c.62.27 1.3.41 2.02.41.32 0 .63-.03.93-.09.26.73.68 1.39 1.22 1.92a7.297 7.297 0 0 0-3.71 6.37c0 .46.37.83.83.83.46 0 .83-.37.83-.83 0-2.28 1.36-4.33 3.46-5.22.06-.02.11-.05.16-.09.63.27 1.32.43 2.05.43.72 0 1.4-.15 2.02-.41a5.69 5.69 0 0 1 3.64 5.29c0 .46.37.83.83.83.46 0 .83-.37.83-.83 0-2.64-1.45-5.08-3.71-6.36.54-.54.96-1.19 1.22-1.92.31.06.62.09.93.09.72 0 1.4-.15 2.02-.41a5.68 5.68 0 0 1 3.64 5.29c0 .46.37.83.83.83.46 0 .83-.37.83-.83 0-2.65-1.45-5.08-3.71-6.36zm-14.51-.32v.1a3.483 3.483 0 0 1-4.12-3.42c0-1.92 1.56-3.48 3.48-3.48 1.48 0 2.79.94 3.28 2.31a5.128 5.128 0 0 0-2.64 4.49zm1.65 0c0-1.92 1.56-3.48 3.48-3.48s3.48 1.56 3.48 3.48-1.56 3.48-3.48 3.48-3.48-1.56-3.48-3.48zm8.61.1v-.1c0-1.93-1.07-3.61-2.64-4.49.49-1.37 1.8-2.31 3.28-2.31 1.92 0 3.48 1.56 3.48 3.48a3.483 3.483 0 0 1-4.12 3.42z"
          style={{
            fill: "#60cc29",
          }}
        />
        <circle
          cx={17.07}
          cy={17.07}
          r={16.09}
          style={{
            fill: "none",
            stroke: "#60cc29",
            strokeWidth: 1.96,
            strokeMiterlimit: 22.9256,
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgAboutUs;

