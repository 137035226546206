import React from 'react';
import {Grid, Box} from '@mui/material';
import {PlatformDrivers} from './PlatformDrivers';
import SvgControl4Logo from './svg/SvgControl4Logo';
import SvgRtiLogo from './svg/SvgRtiLogo';
import SvgElanLogo from './svg/SvgElanLogo';
import shadow from '../assets/shadow.png';

const bottomPadding = 4;
export const PortfolioPanel = () => {
	return (
		<Box>
			<div id={'portfolioPanel'}/>
			<Box style={{
				background: 'linear-gradient(to bottom, white 0%, white 50%, #E0E5E7FF 50%, #E0E5E7FF 100%',
				width: '100%'
			}}>
				<Grid container paddingTop={3} sx={{display: 'flex', justifyContent: 'center'}}>
					{/*<Grid item xs={12} sx={{display: 'flex', justifyContent:'center'}} paddingBottom={4}>*/}
					{/*	<SvgPortfolioIconText/>*/}
					{/*</Grid>*/}
					<Grid item xs={12} md={1} lg={3} sx={{display: {xs: 'none', md: 'flex'}}}/>
					<Grid item xs={12} md={3} lg={2} sx={{display: 'flex', justifyContent: 'center'}}
					      paddingBottom={bottomPadding}>
						<PlatformDrivers Logo={SvgControl4Logo} text={'Your Home. Simplified.'} platform={'control4'}/>
					</Grid>
					<Grid item xs={12} md={3} lg={2} sx={{display: 'flex', justifyContent: 'center'}}
					      paddingBottom={bottomPadding}>
						<PlatformDrivers Logo={SvgRtiLogo} text={'Elegant, intuitive, easy...'} platform={'rti'}/>
					</Grid>
					<Grid item xs={12} md={3} lg={2} sx={{display: 'flex', justifyContent: 'center'}}
					      paddingBottom={bottomPadding}>
						<PlatformDrivers Logo={SvgElanLogo} text={'One Home. One Solution.'} platform={'elan'}/>
					</Grid>
					<Grid item xs={12} md={1} lg={3} sx={{display: {xs: 'none', md: 'flex'}}}/>
				</Grid>
			</Box>
			<img
				src={shadow}
				alt="Shadow"
				style={{width: "100%"}}
			/>
		</Box>
	);
};
