import {pagesList, driverCategoriesList} from '../defaultReducerValues.js';
import {DRIVER_CATEGORIES, DRIVER_CATEGORIES_SELECTED, PAGES, TAB_SELECTED} from '../actions/navigation';

export const navigation = (state = {tabSelected: 0}, action) => {
	switch (action.type) {
		case TAB_SELECTED:
			return {...state, tabSelected: action.payload};
		default:
			return state;
	}
}

export const driverCategorySelected = (state = 0, action) => {
	switch (action.type) {
		case DRIVER_CATEGORIES_SELECTED:
			return action.payload;
		default:
			return state;
	}
}

export const pages = (state = pagesList, action) => {
	switch (action.type) {
		case PAGES:
			return [...state];
		default:
			return state;
	}
}

export const driverCategories = (state = driverCategoriesList, action) => {
	switch (action.type) {
		case DRIVER_CATEGORIES:
			return [...state];
		default:
			return state;
	}
}
