import {AxiosService} from './Axios.Service';

const token = 'uwQddF8J=diuiEoZCUkL5el==Ks-K3=byf8klpDs/F=OGE?QBatYiY/0isjDukNKAXKwaT/DlXcuwnQHDTBNlWEv8aujsXwjlAnRcIG0Q9//UTHy=LmrM3tSEyBEgZSc34/hanuNk10WNnLMHixyaSLRQ6XZCh95=TFcGvgA1W2!NmTOHng!oHWE5aghWp2rZW5e5lOu1ZikIpJiTT6L4ots71m=vAC2BN1n/7WL-2dhk4SBwPJB7aNQ4Bsq!Asx'

export const sendEmailContactForm = async (name, email, message, reason) => {
	console.log('SEND', name, email, message, reason);
	try {
		await AxiosService.post('/api/v1/send', {name, email, message, reason, token});
		return true;
	}
	catch (e) {
		console.log(e);
		return false;
	}
}
