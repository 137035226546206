import React from 'react';
import {Grid, Link, useMediaQuery, useTheme} from '@mui/material';
import shadow from '../assets/shadow.png';
import hop3d from '../assets/hop_3d.png';
import bottomPic from '../assets/bottom_pic.png';
import Typography from '@mui/material/Typography';
import SvgLogo from './svg/SvgLogo';
import SvgHopIcon from './svg/SvgHopIcon';
import SvgDriversIcon from './svg/SvgDriversIcon';
import SvgContactIcon from './svg/SvgContactIcon';
import Box from '@mui/material/Box';
import {connect} from 'react-redux';
import {selectCategory, selectTab} from '../store/actions/navigation';
import {useParams} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
const footerIconWidth = '30px';

const Footer = ({pages, driverCategories}) => {
	const {platform = 'all'} = useParams();

	const theme = useTheme();
	const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md')); // true if screen width < 960px
	return (
		<div>
			<Box style={{maxWidth: '100%', position: "relative", backgroundColor: '#e0e5e7'}}>
				<Grid container>
					<Grid item xs={12}>
						<img
							src={shadow}
							alt="Shadow"
							style={{width: "100%", height: "100%"}}
						/>
					</Grid>
					<Grid item xs={12}>
						<img
							src={hop3d}
							alt="Hop 3D"
							style={{
								position: "absolute",
								top: isSmallerThanMd ? '15px' : "-40px",
								left: isSmallerThanMd ? undefined : '30px',
								right: isSmallerThanMd ? '15px' : undefined,
								width: isSmallerThanMd ? "20%": "10%"

							}}
						/>
					</Grid>
					<Grid item xs={12} md={3}
					      style={{display: 'flex', justifyContent: "center", alignItems: "center"}}/>
					<Grid item xs={12} md={9}>
						<Grid container style={{display: 'flex', justifyContent: "flex-start", paddingLeft:4}}>
							<Grid item xs={12} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingLeft={{xs: 3, md: 0}}>
								<SvgLogo width={'200px'}/>
							</Grid>
							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingTop={3}>
								<SvgHopIcon width={footerIconWidth}/>
							</Grid>
							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingTop={3}>
								<SvgDriversIcon width={footerIconWidth}/>
							</Grid>
							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingTop={3}>
								<SvgContactIcon width={footerIconWidth}/>
							</Grid>
							<Grid item md={6} style={{display: {xs: 'none', md: 'flex'}, justifyContent: "flex-start"}}
							      paddingTop={3}/>

							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingTop={1}>
								<Typography variant="subtitle2">
									HOP SOFT
								</Typography>
							</Grid>
							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingTop={1}>
								<Typography variant="subtitle2">
									DRIVERS
								</Typography>
							</Grid>
							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start"}}
							      paddingTop={1}>
								<Typography variant="subtitle2">
									CONTACT US
								</Typography>
							</Grid>
							<Grid item md={6} style={{display: {xs: 'none', md: 'flex'}, justifyContent: "flex-start"}}
							      paddingTop={3}/>

							<Grid item xs={4} md={2}
							      style={{display: 'flex', justifyContent: "flex-start", alignItems: "flex-start"}}
							      paddingTop={1}>
								<Grid container>
									{pages.map((page, index) => (
										<Grid item xs={12} key={index}>
											<Link
												component={HashLink}
												variant="body2"
												color={"text.primary"}
												underline="hover"
												noWrap={true}
												smooth
												to={`${page.path}#mainPanel`}
											>
												{page.name}
											</Link>
										</Grid>
									))}
								</Grid>
							</Grid>

							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start", alignItems: "flex-start"}}
							      paddingTop={1}>
								<Grid container>
									{driverCategories.map((category, index) => (
										<Grid item xs={12} key={index}>
											<Link
												component={HashLink}
												underline={'hover'}
												smooth
												to={`/portfolio/${platform}/${category.value}#portfolioBrowser`}
												sx={{underline: 'none'}}
											>
												{/*color={categorySelected === index ? "primary" : "text.primary"}*/}
												<Typography variant="body2" color={'text.primary'}>
												{category.name}
												</Typography>
											</Link>
										</Grid>
									))}
								</Grid>
							</Grid>
							<Grid item xs={4} md={2} style={{display: 'flex', justifyContent: "flex-start", alignItems: "flex-start"}}
							      paddingTop={1}>
								<Grid container>
									{/*<Grid item xs={12}>*/}
									{/*	<Typography variant="body2">*/}
									{/*		2 Iona Place*/}
									{/*	</Typography>*/}
									{/*</Grid>*/}
									{/*<Grid item xs={12}>*/}
									{/*	<Typography variant="body2">*/}
									{/*		St. Andrews, NSW, 2566*/}
									{/*	</Typography>*/}
									{/*</Grid>*/}
									{/*<Grid item xs={12}>*/}
									{/*	<Typography variant="body2">*/}
									{/*		Australia*/}
									{/*	</Typography>*/}
									{/*</Grid>*/}
									<Grid item xs={12}>
										<Typography variant="body2">
											ABN: 82639873064
										</Typography>
									</Grid>
									<Grid item xs={12} sx={{display: {xs:'none', md:'flex'}}}>
										<Typography variant="caption">
											For technical support please email:
										</Typography>
									</Grid>
									<Grid item xs={12} sx={{display: {xs:'none', md:'flex'}}}>
										<Link
											variant="body2"
											color="text.primary"
											href={`mailto:admin@hopsoft.com.au`}
											underline="hover">
											admin@hopsoft.com.au
										</Link>
									</Grid>
									<Grid item xs={12} sx={{display: {xs:'none', md:'flex'}}}>
										<Link
											variant="body2"
											color="text.primary"
											href={`mailto:support@hopsoft.com.au`}
											underline="hover">
											support@hopsoft.com.au
										</Link>
									</Grid>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
					<Grid item xs={12} sx={{display: {xs:'flex', md:'none'}, paddingTop:2, justifyContent:'center' }}>
						<Typography variant="caption">
							For technical support please email:
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{display: {xs:'flex', md:'none'}, justifyContent:'center'}}>
						<Link
							variant="body2"
							color="text.primary"
							href={`mailto:admin@hopsoft.com.au`}
							underline="hover">
							admin@hopsoft.com.au
						</Link>
					</Grid>
					<Grid item xs={12} sx={{display: {xs:'flex', md:'none'}, justifyContent:'center'}}>
						<Link
							variant="body2"
							color="text.primary"
							href={`mailto:support@hopsoft.com.au`}
							underline="hover">
							support@hopsoft.com.au
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Box sx={{
							display: 'flex', justifyContent:'center', alignItems:'center',
							backgroundColor: '#60cc29',
							minHeight: '40px',
							minWidth:'100%'
						}}>
							<Typography variant="caption" color={'white'}>
								©2023 by HopSoft | <Link
								variant="caption"
								color="white"
								href={`mailto:admin@hopsoft.com.au`}
								underline="hover">
								admin@hopsoft.com.au
							</Link>
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<img
							src={bottomPic}
							alt="bottomPic"
							style={{width: "100%"}}
						/>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		tabSelected: state.navigation.tabSelected,
		categorySelected: state.drivers.categorySelected,
		pages: state.pages,
		driverCategories: state.drivers.driverCategories
	};
};

export default connect(mapStateToProps, {selectTab, selectCategory})(Footer);
