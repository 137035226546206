import * as React from "react";
const SvgRtiLogo = (props) => (
    <svg viewBox="0 0 58 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g id="rti_logo">
            <g id="Group">
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M6.25421 9.87099C6.25421 10.6039 6.21704 10.9066 6.92692 11.3173C7.46154 11.6271 8.15195 11.7085 8.75738 11.4979C9.25129 11.3244 9.71333 10.7915 10.0497 10.4127C10.8056 9.56119 11.6465 8.80883 12.4431 7.99981L13.0237 7.39261C13.0344 7.38199 13.0644 7.35189 13.0751 7.34127L13.4114 7.00492C14.2576 6.08261 15.3251 5.18862 16.0952 4.26277L16.4085 3.95474C16.7502 3.61308 17.0777 3.28026 17.5078 3.09084L18.4779 2.81999C19.2073 2.71201 23.7392 2.79167 24.858 2.79167C24.6066 3.16697 24.1428 3.53518 23.8118 3.86445C23.4365 4.23798 22.9674 4.46104 22.4345 4.6062C22.0592 4.7071 21.5193 4.70356 21.0856 4.70356C20.3066 4.70356 18.2425 4.61328 17.607 4.79031C17.1042 4.93016 16.5377 5.26297 16.5377 5.89142C16.5377 6.21715 16.5112 6.57828 16.7466 6.81904C17.1874 7.26692 18.9329 8.39104 19.4746 8.79289L23.1656 11.4058C23.3267 11.4996 23.263 11.4713 23.4471 11.5386C24.7819 12.0166 26.6088 10.726 25.6051 9.53641C25.2988 9.1735 23.6135 8.01574 23.1816 7.72188C23.0134 7.60681 22.8417 7.53954 22.7886 7.3395C23.1072 7.31295 23.6507 7.15362 23.9516 7.05449C24.8191 6.77124 25.6971 6.38355 26.3433 5.72501L26.7504 5.35679C27.0248 5.12312 27.2178 4.87528 27.4886 4.64691C27.4975 4.63983 27.5081 4.63098 27.517 4.6239L29.0288 3.18998C29.5581 2.56684 29.5262 1.95433 29.1828 1.25684C29.0483 0.98245 28.896 0.819584 28.6517 0.651409C28.3189 0.421273 27.91 0.214151 27.4869 0.111475C26.8832 -0.0336872 19.7171 0.0530562 18.501 0.0530562C18.1009 0.0530562 17.7362 0.14511 17.3467 0.242475L16.2545 0.545192C15.4986 0.803652 15.033 1.07804 14.47 1.50114C13.5318 2.20748 11.5438 4.39554 10.5418 5.37627L6.71802 9.2514C6.71094 9.25848 6.69856 9.2691 6.6897 9.27618L6.53215 9.42842C6.46311 9.51163 6.25244 9.77717 6.25244 9.87276L6.25421 9.87099Z" fill="#432680"/>
                <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M30.3392 1.24096C30.3392 1.81807 30.4879 2.25002 31.0278 2.51733C31.3004 2.65187 31.3217 2.7156 31.681 2.74392C32.0864 2.77579 32.0652 2.77402 32.4599 2.74038C32.4387 2.92626 32.5024 2.80057 32.228 3.07673L31.9147 3.38476C31.7094 3.60251 31.5129 3.78307 31.3234 3.98134L28.9849 6.39777C27.9617 7.42098 27.7068 8.30789 28.9318 9.52584C29.4346 10.0251 30.4896 10.6447 31.2137 10.912C31.7607 11.1138 32.5078 11.459 33.0866 11.5209C33.9948 11.6165 35.0569 11.7794 35.9102 11.4112C36.0182 11.3652 36.043 11.3368 36.1616 11.2501C36.3386 11.1209 36.4006 11.0731 36.5245 10.8889C36.9883 10.2039 36.7263 9.53292 36.0625 9.06203C35.195 8.44597 34.5188 9.23197 32.6228 8.31497C32.3591 8.18751 32.2458 8.05828 32.0475 8.01226C32.0687 7.76442 32.7202 7.19793 32.9592 6.96071C33.0955 6.8244 33.1344 6.74297 33.2743 6.60312L36.6343 3.24491C37.3105 2.56867 37.0397 2.79172 37.422 2.79172H43.8818C44.4181 2.79172 45.1634 2.5368 45.4555 2.1438C46.0486 1.34718 45.6998 0.58065 44.9935 0.231906C44.8749 0.173487 44.8359 0.154014 44.7456 0.116838C44.5226 0.0265541 44.5456 0.00354055 44.5332 0H44.4482H31.527C31.5164 0 30.6614 0.279703 30.4773 0.759447C30.4418 0.853272 30.3374 1.15953 30.3374 1.24096H30.3392Z" fill="#432680"/>
                <path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M0 16.1764L1.25336 16.0348C1.4711 16.0064 1.68176 16.0365 1.8995 16.0082C3.01831 15.8613 4.03268 15.8152 5.13025 15.6736C5.34799 15.6453 5.55866 15.6754 5.7764 15.6471C5.96051 15.6223 6.17117 15.5621 6.40839 15.555C7.18731 15.5338 8.20345 15.4293 8.96289 15.3196L10.2941 15.2027C10.5154 15.1779 10.7119 15.208 10.9385 15.1744C11.1669 15.1408 11.351 15.0983 11.5794 15.0912L14.125 14.8469C14.3605 14.8168 14.58 14.8487 14.8154 14.8133L18.0532 14.4858C18.2728 14.4574 18.471 14.4858 18.6905 14.4521C19.6199 14.3105 20.9087 14.2556 21.9284 14.1246C22.1479 14.0963 22.3444 14.1229 22.5657 14.091L24.5006 13.9069C24.9432 13.9034 26.6408 13.6555 27.0816 13.6449L33.5396 13.0749C33.9237 13.0465 34.4017 12.9545 34.7859 12.9262C35.0709 12.9049 35.2125 12.9102 35.4568 12.8748L37.4183 12.7173C37.944 12.6925 39.2381 12.4517 40.0011 12.457C40.5092 12.4606 40.8862 12.3986 41.2952 12.303L7.33956 12.2004C6.49868 12.2004 5.34977 12.1331 4.54783 12.1986C4.2699 12.2216 4.23272 12.3491 3.95125 12.5845L1.83047 14.4433C1.67645 14.5619 1.59502 14.6663 1.45163 14.7885C1.44277 14.7956 1.43216 14.8044 1.42331 14.8115L0.322191 15.7781C0.0354068 16.0295 0.00708109 15.863 0 16.1799V16.1764Z" fill="#432680"/>
                <path id="Vector_4" fillRule="evenodd" clipRule="evenodd" d="M39.2275 10.2339C39.2275 11.2483 40.4968 11.7334 41.2439 11.7334C41.5608 11.7334 41.9555 11.6059 42.1963 11.4448C42.5981 11.1775 43.1398 10.5862 43.5063 10.2216L43.9117 9.80023C43.9188 9.79315 43.9312 9.78253 43.9382 9.77545L51.4035 2.20045C51.6053 1.96146 51.6672 1.87826 51.685 1.73487V1.24096C51.685 1.17723 51.515 0.874515 51.4796 0.824947C51.4194 0.738204 51.4123 0.739974 51.338 0.656771C51.3043 0.619596 51.2937 0.607204 51.2654 0.575339C51.0406 0.32573 50.359 0 50.29 0H49.2048H49.1942H49.1092C49.0756 0 49.1871 0.00708109 49.095 0.0442568L48.764 0.178798C48.5604 0.276163 48.5622 0.304487 48.417 0.45319L45.2783 3.61844C44.8641 4.05216 44.4587 4.46463 44.025 4.89658L42.5433 6.41193C42.4884 6.46858 42.5379 6.42078 42.4618 6.48628L41.5183 7.45462C41.0828 7.89011 40.6862 8.28665 40.2525 8.72037C39.9179 9.05495 39.2311 9.62852 39.2311 10.2322L39.2275 10.2339Z" fill="#432680"/>
            </g>
        </g>
    </svg>
);
export default SvgRtiLogo;

