import React, { useEffect, useRef, useState} from "react";
import {CircularProgress, SvgIcon} from '@mui/material';

function useDynamicSVGImport(name, options = {}) {
	const ImportedIconRef = useRef();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const { onCompleted, onError } = options;
	useEffect(() => {
		setLoading(true);
		const importIcon = async () => {
			try {
				if (name !== undefined) {
					ImportedIconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/svg/icons/${name}.svg`)).default;
					if (onCompleted) {
						onCompleted(name, ImportedIconRef.current);
					}
				}
			} catch (err) {
				if (onError) {
					onError(err);
				}
				setError(err);
			} finally {
				setLoading(false);
			}
		};
		importIcon();
	}, [name, onCompleted, onError]);
	// console.log(ImportedIconRef.current)
	return { error, loading, SvgIconRaw: ImportedIconRef.current };
}

/**
 * Simple wrapper for dynamic SVG import hook. You can implement your own wrapper,
 * or even use the hook directly in your components.
 */
const DynamicIcon = ({ name = 'all', onCompleted, onError, ...rest }) => {
	const { error, loading, SvgIconRaw } = useDynamicSVGImport(name, {
		onCompleted,
		onError
	});
	if (error) {
		console.log(error.message)
		return <CircularProgress/>;
	}
	if (loading) {
		return <CircularProgress/>;
	}
	if (SvgIconRaw) {
		return (<SvgIcon {...rest} >
			<SvgIconRaw/>
		</SvgIcon>);
	}
	return null;
};
// export const WeatherIconNoStyle = ({ name, onCompleted, onError, ...rest }) => {
// 	const { error, loading, SvgIconRaw } = useDynamicSVGImport(name, {
// 		onCompleted,
// 		onError
// 	});
// 	if (error) {
// 		console.log(error.message)
// 		return <CircularProgress/>;
// 	}
// 	if (loading) {
// 		return <CircularProgress/>;
// 	}
// 	if (SvgIconRaw) {
// 		return <SvgIconRaw {...rest} />
// 	}
// 	return null;
// };

export default DynamicIcon;
